import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from '@nuxt/ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _158dd0ff = () => interopDefault(import('../pages/admin.vue' /* webpackChunkName: "pages/admin" */))
const _15b196e0 = () => interopDefault(import('../pages/admin/gebruikers/index.vue' /* webpackChunkName: "pages/admin/gebruikers/index" */))
const _5021cda4 = () => interopDefault(import('../pages/admin/opdrachtgevers/index.vue' /* webpackChunkName: "pages/admin/opdrachtgevers/index" */))
const _cb1da416 = () => interopDefault(import('../pages/admin/projecten/index.vue' /* webpackChunkName: "pages/admin/projecten/index" */))
const _6f94e044 = () => interopDefault(import('../pages/admin/gebruikers/create.vue' /* webpackChunkName: "pages/admin/gebruikers/create" */))
const _4da85750 = () => interopDefault(import('../pages/admin/opdrachtgevers/create.vue' /* webpackChunkName: "pages/admin/opdrachtgevers/create" */))
const _492e492e = () => interopDefault(import('../pages/admin/projecten/create.vue' /* webpackChunkName: "pages/admin/projecten/create" */))
const _7828c4b6 = () => interopDefault(import('../pages/admin/projecten/update.vue' /* webpackChunkName: "pages/admin/projecten/update" */))
const _2477d148 = () => interopDefault(import('../pages/admin/gebruikers/_id.vue' /* webpackChunkName: "pages/admin/gebruikers/_id" */))
const _3d2d8f16 = () => interopDefault(import('../pages/admin/opdrachtgevers/_id.vue' /* webpackChunkName: "pages/admin/opdrachtgevers/_id" */))
const _0f2f7340 = () => interopDefault(import('../pages/admin/projecten/_id/index.vue' /* webpackChunkName: "pages/admin/projecten/_id/index" */))
const _f707d824 = () => interopDefault(import('../pages/admin/projecten/_id/update/index.vue' /* webpackChunkName: "pages/admin/projecten/_id/update/index" */))
const _6831719f = () => interopDefault(import('../pages/admin/projecten/_id/update/_updateid.vue' /* webpackChunkName: "pages/admin/projecten/_id/update/_updateid" */))
const _00469134 = () => interopDefault(import('../pages/inloggen/index.vue' /* webpackChunkName: "pages/inloggen/index" */))
const _77a481cc = () => interopDefault(import('../pages/instellingen.vue' /* webpackChunkName: "pages/instellingen" */))
const _3ccb4296 = () => interopDefault(import('../pages/projecten/index.vue' /* webpackChunkName: "pages/projecten/index" */))
const _fc5a655a = () => interopDefault(import('../pages/inloggen/callback.vue' /* webpackChunkName: "pages/inloggen/callback" */))
const _4210f562 = () => interopDefault(import('../pages/inloggen/over.vue' /* webpackChunkName: "pages/inloggen/over" */))
const _29212172 = () => interopDefault(import('../pages/inloggen/wachtwoord-vergeten.vue' /* webpackChunkName: "pages/inloggen/wachtwoord-vergeten" */))
const _dbaaeb46 = () => interopDefault(import('../pages/projecten/_id.vue' /* webpackChunkName: "pages/projecten/_id" */))
const _2f542c8c = () => interopDefault(import('../pages/wachtwoord-instellen/_token.vue' /* webpackChunkName: "pages/wachtwoord-instellen/_token" */))
const _37c305fc = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _158dd0ff,
    pathToRegexpOptions: {"strict":true},
    name: "admin",
    children: [{
      path: "gebruikers",
      component: _15b196e0,
      pathToRegexpOptions: {"strict":true},
      name: "admin-gebruikers"
    }, {
      path: "opdrachtgevers",
      component: _5021cda4,
      pathToRegexpOptions: {"strict":true},
      name: "admin-opdrachtgevers"
    }, {
      path: "projecten",
      component: _cb1da416,
      pathToRegexpOptions: {"strict":true},
      name: "admin-projecten"
    }, {
      path: "gebruikers/create",
      component: _6f94e044,
      pathToRegexpOptions: {"strict":true},
      name: "admin-gebruikers-create"
    }, {
      path: "opdrachtgevers/create",
      component: _4da85750,
      pathToRegexpOptions: {"strict":true},
      name: "admin-opdrachtgevers-create"
    }, {
      path: "projecten/create",
      component: _492e492e,
      pathToRegexpOptions: {"strict":true},
      name: "admin-projecten-create"
    }, {
      path: "projecten/update",
      component: _7828c4b6,
      pathToRegexpOptions: {"strict":true},
      name: "admin-projecten-update"
    }, {
      path: "gebruikers/:id",
      component: _2477d148,
      pathToRegexpOptions: {"strict":true},
      name: "admin-gebruikers-id"
    }, {
      path: "opdrachtgevers/:id",
      component: _3d2d8f16,
      pathToRegexpOptions: {"strict":true},
      name: "admin-opdrachtgevers-id"
    }, {
      path: "projecten/:id",
      component: _0f2f7340,
      pathToRegexpOptions: {"strict":true},
      name: "admin-projecten-id"
    }, {
      path: "projecten/:id/update",
      component: _f707d824,
      pathToRegexpOptions: {"strict":true},
      name: "admin-projecten-id-update"
    }, {
      path: "projecten/:id/update/:updateid",
      component: _6831719f,
      pathToRegexpOptions: {"strict":true},
      name: "admin-projecten-id-update-updateid"
    }]
  }, {
    path: "/inloggen",
    component: _00469134,
    pathToRegexpOptions: {"strict":true},
    name: "inloggen"
  }, {
    path: "/instellingen",
    component: _77a481cc,
    pathToRegexpOptions: {"strict":true},
    name: "instellingen"
  }, {
    path: "/projecten",
    component: _3ccb4296,
    pathToRegexpOptions: {"strict":true},
    name: "projecten"
  }, {
    path: "/inloggen/callback",
    component: _fc5a655a,
    pathToRegexpOptions: {"strict":true},
    name: "inloggen-callback"
  }, {
    path: "/inloggen/over",
    component: _4210f562,
    pathToRegexpOptions: {"strict":true},
    name: "inloggen-over"
  }, {
    path: "/inloggen/wachtwoord-vergeten",
    component: _29212172,
    pathToRegexpOptions: {"strict":true},
    name: "inloggen-wachtwoord-vergeten"
  }, {
    path: "/projecten/:id",
    component: _dbaaeb46,
    pathToRegexpOptions: {"strict":true},
    name: "projecten-id"
  }, {
    path: "/wachtwoord-instellen/:token?",
    component: _2f542c8c,
    pathToRegexpOptions: {"strict":true},
    name: "wachtwoord-instellen-token"
  }, {
    path: "/",
    component: _37c305fc,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }],

  fallback: false
}

function decodeObj(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = decode(obj[key])
    }
  }
}

export function createRouter () {
  const router = new Router(routerOptions)

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    const r = resolve(to, current, append)
    if (r && r.resolved && r.resolved.query) {
      decodeObj(r.resolved.query)
    }
    return r
  }

  return router
}
