import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_swplugin_4cd1d416 from 'nuxt_plugin_swplugin_4cd1d416' // Source: ./sw.plugin.js (mode: 'client')
import nuxt_plugin_nuxticons_57f7e46c from 'nuxt_plugin_nuxticons_57f7e46c' // Source: ./nuxt-icons.js (mode: 'all')
import nuxt_plugin_nuxtleaflet_3ee7da2e from 'nuxt_plugin_nuxtleaflet_3ee7da2e' // Source: ./nuxt-leaflet.js (mode: 'client')
import nuxt_plugin_nuxtgooglemaps_5960760a from 'nuxt_plugin_nuxtgooglemaps_5960760a' // Source: ./nuxt-google-maps.js (mode: 'all')
import nuxt_plugin_moment_0ea90288 from 'nuxt_plugin_moment_0ea90288' // Source: ./moment.js (mode: 'all')
import nuxt_plugin_libplugin60a6f84c_1cb251b0 from 'nuxt_plugin_libplugin60a6f84c_1cb251b0' // Source: ./lib.plugin.60a6f84c.js (mode: 'all')
import nuxt_plugin_toast_dca7a9ea from 'nuxt_plugin_toast_dca7a9ea' // Source: ./toast.js (mode: 'client')
import nuxt_plugin_axios_75084348 from 'nuxt_plugin_axios_75084348' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_apollomodule_50cee3b0 from 'nuxt_plugin_apollomodule_50cee3b0' // Source: ./apollo-module.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_30054604 from 'nuxt_plugin_cookieuniversalnuxt_30054604' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_axios_630a4306 from 'nuxt_plugin_axios_630a4306' // Source: ../plugins/axios (mode: 'all')
import nuxt_plugin_bugsnag_4aea807c from 'nuxt_plugin_bugsnag_4aea807c' // Source: ../plugins/bugsnag (mode: 'all')
import nuxt_plugin_fontawesome_5e0b9dfb from 'nuxt_plugin_fontawesome_5e0b9dfb' // Source: ../plugins/fontawesome (mode: 'all')
import nuxt_plugin_validation_b46593c6 from 'nuxt_plugin_validation_b46593c6' // Source: ../plugins/mixins/validation (mode: 'all')
import nuxt_plugin_directives_f60b4746 from 'nuxt_plugin_directives_f60b4746' // Source: ../plugins/directives (mode: 'all')
import nuxt_plugin_filters_0a501042 from 'nuxt_plugin_filters_0a501042' // Source: ../plugins/filters (mode: 'all')
import nuxt_plugin_contentplaceholders_a6d63566 from 'nuxt_plugin_contentplaceholders_a6d63566' // Source: ../plugins/content-placeholders (mode: 'all')
import nuxt_plugin_vuelazysizesclient_c150bd08 from 'nuxt_plugin_vuelazysizesclient_c150bd08' // Source: ../plugins/vue-lazysizes.client.js (mode: 'client')
import nuxt_plugin_proj4client_8552d248 from 'nuxt_plugin_proj4client_8552d248' // Source: ../plugins/proj4.client.js (mode: 'client')
import nuxt_plugin_debounce_7d738856 from 'nuxt_plugin_debounce_7d738856' // Source: ../plugins/debounce.js (mode: 'all')
import nuxt_plugin_plugin_38bc92d0 from 'nuxt_plugin_plugin_38bc92d0' // Source: ./auth/plugin.js (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    return this.$root.$options.$nuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule
const baseStoreOptions = { preserveState: process.client }

function registerModule (path, rawModule, options = {}) {
  return originalRegisterModule.call(this, path, rawModule, { ...baseStoreOptions, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Ecologboek","titleTemplate":(t) => {
      return t + (t === 'Ecologboek' ? '' : ' – Ecologboek');
    },"htmlAttrs":{"lang":"nl"},"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"description","hid":"description","content":""},{"name":"apple-mobile-web-app-title","hid":"apple-mobile-web-app-title","content":"Ecologboek"},{"name":"og:title","hid":"og:title","content":"Ecologboek"},{"name":"og:description","hid":"og:description","content":""},{"name":"og:site_name","hid":"og:site_name","content":"Ecologboek"},{"hid":"mobile-web-app-capable","name":"mobile-web-app-capable","content":"yes"},{"hid":"author","name":"author","content":"BTTR"},{"hid":"theme-color","name":"theme-color","content":"#b9005f"},{"hid":"og:type","name":"og:type","property":"og:type","content":"website"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"},{"rel":"stylesheet","href":"https:\u002F\u002Fuse.typekit.net\u002Fnri1fcj.css"},{"rel":"manifest","href":"\u002F_nuxt\u002Fmanifest.269020cb.json"},{"rel":"shortcut icon","href":"\u002F_nuxt\u002Ficons\u002Ficon_64.c8Yoo8Qojb0.png"},{"rel":"apple-touch-icon","href":"\u002F_nuxt\u002Ficons\u002Ficon_512.c8Yoo8Qojb0.png","sizes":"512x512"}],"style":[],"script":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.client && typeof nuxt_plugin_swplugin_4cd1d416 === 'function') {
    await nuxt_plugin_swplugin_4cd1d416(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxticons_57f7e46c === 'function') {
    await nuxt_plugin_nuxticons_57f7e46c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_nuxtleaflet_3ee7da2e === 'function') {
    await nuxt_plugin_nuxtleaflet_3ee7da2e(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtgooglemaps_5960760a === 'function') {
    await nuxt_plugin_nuxtgooglemaps_5960760a(app.context, inject)
  }

  if (typeof nuxt_plugin_moment_0ea90288 === 'function') {
    await nuxt_plugin_moment_0ea90288(app.context, inject)
  }

  if (typeof nuxt_plugin_libplugin60a6f84c_1cb251b0 === 'function') {
    await nuxt_plugin_libplugin60a6f84c_1cb251b0(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_toast_dca7a9ea === 'function') {
    await nuxt_plugin_toast_dca7a9ea(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_75084348 === 'function') {
    await nuxt_plugin_axios_75084348(app.context, inject)
  }

  if (typeof nuxt_plugin_apollomodule_50cee3b0 === 'function') {
    await nuxt_plugin_apollomodule_50cee3b0(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_30054604 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_30054604(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_630a4306 === 'function') {
    await nuxt_plugin_axios_630a4306(app.context, inject)
  }

  if (typeof nuxt_plugin_bugsnag_4aea807c === 'function') {
    await nuxt_plugin_bugsnag_4aea807c(app.context, inject)
  }

  if (typeof nuxt_plugin_fontawesome_5e0b9dfb === 'function') {
    await nuxt_plugin_fontawesome_5e0b9dfb(app.context, inject)
  }

  if (typeof nuxt_plugin_validation_b46593c6 === 'function') {
    await nuxt_plugin_validation_b46593c6(app.context, inject)
  }

  if (typeof nuxt_plugin_directives_f60b4746 === 'function') {
    await nuxt_plugin_directives_f60b4746(app.context, inject)
  }

  if (typeof nuxt_plugin_filters_0a501042 === 'function') {
    await nuxt_plugin_filters_0a501042(app.context, inject)
  }

  if (typeof nuxt_plugin_contentplaceholders_a6d63566 === 'function') {
    await nuxt_plugin_contentplaceholders_a6d63566(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuelazysizesclient_c150bd08 === 'function') {
    await nuxt_plugin_vuelazysizesclient_c150bd08(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_proj4client_8552d248 === 'function') {
    await nuxt_plugin_proj4client_8552d248(app.context, inject)
  }

  if (typeof nuxt_plugin_debounce_7d738856 === 'function') {
    await nuxt_plugin_debounce_7d738856(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_38bc92d0 === 'function') {
    await nuxt_plugin_plugin_38bc92d0(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // If server-side, wait for async component to be resolved first
  if (process.server && ssrContext && ssrContext.url) {
    await new Promise((resolve, reject) => {
      router.push(ssrContext.url, resolve, (err) => {
        // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
        if (!err._isRouter) return reject(err)
        if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

        // navigated to a different route in router guard
        const unregister = router.afterEach(async (to, from) => {
          ssrContext.url = to.fullPath
          app.context.route = await getRouteData(to)
          app.context.params = to.params || {}
          app.context.query = to.query || {}
          unregister()
          resolve()
        })
      })
    })
  }

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
